html {
    /* To make use of full height of page*/
    min-height: 100%;
    margin: 0;
}

body > #root > div {
    height: 100vh;
}

body {
    font-family: "Open Sans", sans-serif;
    min-height: 100%;
    max-width: 800px;
    margin: auto;
    /*background: #EEEDF5;*/
    background: transparent linear-gradient(180deg, #EEEDF5B5 0%, #F0EFF6BF 59%, #F6F6F9 73%, #FCFCFD 81%, #FEFEFE 89%, #FFFFFF 100%) 0 0 no-repeat padding-box;
}

button {
    font-family: inherit;
    cursor: pointer;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

input[type="radio"] {
    display: none;
}
