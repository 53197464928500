
.react-datepicker-wrapper { 
  width: 100%;
}

.react-datepicker__input-container input {
  /*Quick and dirty styles */
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  border-radius: 8px;
  background: #FFFFFF;
  color: #495057;
  font-size: 1em;
  padding: calc(0.75em - 1px) calc(0.75em - 1px);
  box-shadow: 3px 3px 25px #0000001C;
  border: 2px solid #FFFFFF;
  outline: none;
}

.react-datepicker__input-container input:focus {
  border: 2px solid #6C63FF5C;
}